import InputEditorItem from 'o365.modules.inputEditorsItem.ts';

export default class InputEditorsList{
    _items:Array<InputEditorItem> = [];

    changeEvt:Function|null = null;

    get items(){
        return this._items;
    }


    constructor(){

    }

    updateItems(pData:Array<any>){
         this._clearItems();
        
        pData.forEach((item:any)=>{
            this._items.push(new InputEditorItem(item,(col,type,val)=>this._changeEvt(col,type,val)));
        })
    }


    updateItemValues(pData:Array<any>){
        pData.forEach((item:any)=>{
            const vItem = this._items.find(x=>x.column === item.PropertyName);
            if(vItem){
                vItem.setValue(item.Value);
            }
        })
    }

    private _changeEvt(pColumn:any,pType:string,pValue:any){
        if(this.changeEvt)
            this.changeEvt.call(this,pColumn,pType,pValue);
   
    }

    private _clearItems(){
        this._items.splice(0,this._items.length);
    }
}